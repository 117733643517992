#project-background-main-container {
    scroll-snap-align: start;
    width: 100%;
    padding: 5% 0;
    overflow-x: hidden;
}

#project-background-heading-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2% 5%;
    justify-content: flex-end;
}

.project-background {
    font-weight: 500;
    font-size: 50px;
    line-height: 66px;
    color: #053623;
    text-align: right;
}

#project-background-info-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 2.5% 0;
}

#project-background-green {
    transform: rotate(-9.79deg);
    position: relative;
    left: -5rem;
}

#project-background-info {
    transform: rotate(20deg);
    opacity: 0;

    position: relative;
    left: -10rem;
    transition: all 1s linear;
}

#project-background-info.active {
    transform: rotate(2.5deg);
    opacity: 1;
}

#project-info {
/*     transform: rotate(-2.5deg); */
}

#circle-dot-project {
    margin: 20px;
    position: absolute;

    transform: scale(1.5);
    opacity: 0;
    transition: all 2s linear;
}

#circle-dot-project.active {
    transform: scale(1);
    opacity: 1;
}


@media only screen and (max-width: 1131px) {
    #project-info {
        padding: 10% 5%;
    }

    #project-background-info {
        left: -8rem;
        width: 110%;
    }

    #project-background-green {
        width: 110%;
    }
}

@media only screen and (max-width: 900px) {
    .project-background {
        font-size: 40px;
        line-height: 50px;
    }

    #project-info {
        padding: 10%;
        font-size: 20px;
        line-height: 25px;
    }

    #project-background-info {
        width: 130%;
        left: -9rem;
    }

    #project-background-green {
        width: 130%;
        left: -10rem;
    }

}

@media only screen and (max-width: 600px) {
    .project-background {
        font-size: 30px;
        line-height: 40px;
    }

    #project-info {
        padding: 12% 10%;
        font-size: 18px;
        line-height: 22px;
    }

    #project-background-info {
        width: 150%;
        left: -10rem;
    }

}

@media only screen and (max-width: 500px) {
    #project-info {
        padding: 15% 10%;
        font-size: 16px;
        line-height: 20px;
    }

    #project-background-green {
        width: 150%;
        left: -8rem;
    }

    #project-background-info {
        left: -7rem;
    }

}

@media only screen and (max-width: 400px) {
    .project-background {
        font-size: 25px;
        line-height: 30px;
    }

    #project-info {
        font-size: 14px;
        line-height: 18px;
    }

    #project-background-info {
        left: -8rem;
    }

    #circle-dot-project {
        margin: 15px;
    }
}

@media only screen and (max-width: 300px) {
    .project-background {
        font-size: 20px;
        line-height: 25px;
    }

    #project-info {
        font-size: 12px;
        line-height: 16px;
    }

    #project-background-info {
        left: -4rem;
    }

    #project-background-green {
        left: -3rem;
    }

    #circle-dot-project {
        margin: 10px;
    }

}
