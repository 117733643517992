.slide-right {
    width: 100%;
    overflow: hidden;
    animation: 2s slide-right 2s forwards;
    transform: translateY(100px);
    opacity: 0;
}

@keyframes slide-right {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}