.need-for-project-main-container {
    scroll-snap-align: start;
    width: 100%;
    padding: 5% 0;
    overflow-x: hidden;
}

#why-we-felt-heading-container {
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
}

.why-we-felt {
    font-weight: 500;
    font-size: 50px;
    line-height: 66px;
    color: #053623;
    text-align: left;
}

#need-for-project-info-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 5% 0;
}

.need-background-div {
    width: 100%;
    z-index: 1;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

#need-for-project-info {
    transform: rotate(-20deg);
    opacity: 0;
    position: relative;
    left: 10rem;
    transition: all 1s linear;
}

#need-for-project-info.active {
    transform: rotate(-3.05deg);
    opacity: 1;
}

.circle-dot {
    background: #389872;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 20px;
    height: 20px;
}

#circle-dot-need {
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;

    transform: scale(1.5);
    opacity: 0;
    transition: all 2s linear;
}

#circle-dot-need.active {
    transform: scale(1);
    opacity: 1;
}

.need-background-info {
    width: 100%;
    height: 100%;
    padding: 8% 5%;
    text-align: justify;
    text-justify: inter-word;
    font-size: 24px;
    line-height: 34px;
    color: #000000;
}

#need-info {
/*     transform: rotate(3.05deg); */
}

.need-background-div-green {
    width: 100%;
    height: 100%;
    background: #389872;
    border-radius: 10px;
}

#need-for-project-green {
    transform: rotate(8.07deg);

    position: relative;
    left: 5rem;
}

@media only screen and (max-width: 1131px) {

    #need-for-project-info {
        left: 8rem;
        width: 110%;
    }

    #need-info {
        padding: 10% 5%;
    }

    #need-for-project-green {
        width: 110%;
        left: 4rem;
    }
}

@media only screen and (max-width: 900px) {

    .why-we-felt {
        font-size: 40px;
        line-height: 50px;
    }

    #need-for-project-info {
        left: 1rem;
        width: 130%;
    }

    #need-info {
        padding: 10%;
        font-size: 20px;
        line-height: 25px;
    }

    #need-for-project-green {
        width: 130%;
        left: 3rem;
    }
}

@media only screen and (max-width: 600px) {
    .why-we-felt {
        font-size: 30px;
        line-height: 40px;
    }

    #need-info {
        padding: 12% 10%;
        font-size: 18px;
        line-height: 22px;
    }

    #need-for-project-info {
        width: 150%;
    }

}

@media only screen and (max-width: 500px) {
    #need-info {
        padding: 15% 10%;
        font-size: 16px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .why-we-felt {
        font-size: 25px;
        line-height: 30px;
    }

    #need-info {
        font-size: 14px;
        line-height: 18px;
    }

    #need-for-project-green {
        left: 2rem;
    }

    #circle-dot-need {
        margin: 15px;
    }
}

@media only screen and (max-width: 300px) {

    .why-we-felt {
        font-size: 20px;
        line-height: 25px;
    }

    #need-info {
        font-size: 12px;
        line-height: 16px;
    }

    #need-for-project-green {
        left: 1rem;
    }
}
