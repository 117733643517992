.footer-main-container {
    width: 100%;
    padding: 0.5% 5%;

    display: grid;

    grid-template-areas: "info logo";
    grid-template-columns: 1fr 1fr;

    align-items: center;

    background: #FFFFFF;
    border-top: 13px solid #4AB48A;

    overflow-x: hidden;
}

.footer-designer-container{
    width: 100%;
    padding: 3% 5% 3%;

    display: grid;
    grid-template-columns: 1fr;

    align-items: center;
    justify-items: center;

    background: #FFFFFF;
    font-weight: bold;

    overflow-x: hidden;
}

.footer-designer-container a {
    color: #0060B6;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 12px;
    background: none;
}

.footer-logo {
    grid-area: logo;
    height: 90%;
    justify-self: end;

    animation: glow 1s infinite alternate;
}

@keyframes glow {
    from {
        filter: drop-shadow(0px 15px 5px rgba(94, 131, 166, 0.75));
    }
    to {
        filter: drop-shadow(0px 15px 50px rgba(94, 131, 166, 1));
    }
}

.footer-info-container {
    grid-area: info;
    display: flex;
    flex-direction: column;
}

.footer-info-container a {
    color: #0060B6;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    background: none;
}

.footer-info-container a:hover {
    color: #00A0C6;
    text-decoration: none;
    cursor: pointer;
}

.footer-info-container p:nth-child(2) {
    margin: 20px 0 3px;
    font-size: 24px;
    font-weight: 500;
}

.footer-info-container p:last-child {
    font-size: 18px;
    font-weight: 400;
}

@media only screen and (max-width: 700px) {
    .footer-main-container {
        width: 100vw;
        padding: 2% 5%;
        grid-template-columns: 1fr;
        grid-template-areas: "logo"
    "info";
    }

    .footer-logo {
        height: 60%;
        justify-self: center;
    }
}