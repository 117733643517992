.App {
    /*scroll-snap-type: y proximity;*/
    height: fit-content;
    max-width: 100em;
    margin: auto;
    overflow-x: hidden;
}

.gm-style .gm-style-iw-c {
    padding-right: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    max-width: 500px !important;
    max-height: 326px !important;
    min-width: 0 !important;
    position: absolute;
    box-sizing: border-box;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.gm-style-iw-t {
    top: -50px;
}


.gm-style-iw-d {
    overflow: hidden !important;
}
