#address-main-container {
    width: 90%;
    margin: 5%;
    padding: 2.5%;
    background: rgba(74, 180, 138, 0.08);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
}

.address-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 5%;
}

.address-heading {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #2E8764;
    margin-bottom: 20px;
}

.address-heading a{
    cursor: pointer;
    color: #00A0C6;
}

.address-text {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    color: #000000;
}


@media only screen and (max-width: 900px) {
    .address-heading {
        font-size: 24px;
        line-height: 30px;
    }

    .address-text {
        font-size: 18px;
        line-height: 24px;
    }
}


@media only screen and (max-width: 700px) {
    .address-heading {
        font-size: 20px;
        line-height: 25px;
    }

    .address-text {
        font-size: 16px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .address-card-container {
        width: 90%;
        /*padding: 5%;*/
    }
}