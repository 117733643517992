#nav-bar {
    width: 100%;
    height: 7vh;
    padding: 0 3.5%;

    position: sticky;
    top: 0;
    background: #FFFFFFA9;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#nav-bar > img {
    height: 90%;
    margin: auto 0;

    filter: drop-shadow(0px 15px 4px rgba(94, 131, 166, 0.75));
    backdrop-filter: blur(4px);
}

.nav-items {
    display: flex;
    justify-content: space-between;
    width: 30%;
    z-index: 2;
}

.nav-items > li {
    list-style: none;
    text-align: center;
}

.nav-items > li > button {
    display: flex;
    color: #2E8764;

    text-decoration: none;
    text-transform: uppercase;

    text-align: right;

    font-weight: bold;
    font-size: 14px;
    letter-spacing: 3px;
    background: none;
}

.burger {
    display: none;
    cursor: pointer;
}

.burger > div {
    width: 25px;
    height: 3px;
    background-color: #2E8764;
    margin: 5px;
    transition: all 0.3s ease;
}


@media only screen and (max-width: 1020px) {
    .nav-items {
        /*width: 75%;*/
        width: 42%;
    }
}

@media only screen and (max-width: 768px) {
    .nav-items {

        display: flex;
        justify-content: space-evenly;

        width: 100%;
        position: absolute;
        right: 0;
        height: 93vh;
        top: 7vh;
        background-color: #FFFFFFC9;
        flex-direction: column;
        align-items: center;
        transform: translateX(-100%);
        transition: transform 0.5s ease-in;
    }

    .nav-items li {
        opacity: 0;
    }

    .nav-items > li > a {
        font-size: 16px;
    }

    .burger {
        display: block;
    }
}

.nav-active {
    transform: translateX(0%);
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
    opacity: 0;
}

.toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
}