.top-section-main-container {
    width: 100%;
    scroll-snap-align: start;
    display: grid;
    grid-template-rows: 1.2fr 0.8fr;
    grid-template-columns: 1fr;
    overflow-x: hidden;
}


#about-us-text-heading {
    width: 100%;
    padding-top: 20px;
    font-weight: bold;
    font-size: 96px;
    color: #e9ffe7;
    text-align: center;
    /*background: url("../../../assets/aboutUsIndustry.svg") no-repeat bottom;*/
    background: linear-gradient(#646464, #878787), url("../../../assets/AboutUsPage/aboutUsBackground.jpg") no-repeat bottom;
    background-blend-mode: hard-light;
    background-size: cover;
}

#about-us-text-heading::after {
    filter: contrast(10%);
}

#zircon-info {
    margin: 2.5% 5%;
    padding: 1%;
    background: rgba(74, 180, 138, 0.08);

    font-size: 24px;
    line-height: 29px;
    text-align: justify;
    text-justify: inter-word;

    color: #000000;
}


@media only screen and (max-width: 950px) {
    .top-section-main-container {
        grid-template-rows: 0.9fr 0.5fr;
    }

    #about-us-text-heading {
        font-size: 48px;
        background-size: cover;
    }

    #zircon-info {
        font-size: 20px;
        line-height: 24px;
        padding: 5%;
    }
}

@media only screen and (max-width: 550px) {
    .top-section-main-container {
        grid-template-rows: 0.8fr 0.5fr;
    }

    #about-us-text-heading {
        padding-top: 5%;
    }

    #zircon-info {
        font-size: 16px;
        margin: 2.5%;
        padding: 5%;
    }
}

@media only screen and (max-width: 400px) {
    .top-section-main-container {
        grid-template-rows: 0.6fr 0.4fr;
    }

    #about-us-text-heading {
        padding-top: 5%;
        font-size: 36px;
        line-height: 38px;
        background-size: cover;
    }

    #zircon-info {
        font-size: 14px;
        margin: 5% 1%;
        padding: 5%;
    }
}