#contact-us-top-section-main-container {
    width: 100%;
}

#contact-us-top-background {
    background-color: #389872;
    position: relative;
    width: 100%;

    min-height: 250px;

}

.contact-us-heading-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}


#contact-text {
    font-weight: 600;
    font-size: 70px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    left: -1rem;
    top: -0.25rem;
}

#phone-img {
    height: 150px;
}

#top-right-img {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translate(-5%, -5%);
}

#bottom-left-img {
    position: absolute;
    bottom: 5%;
    left: 5%;
    transform: translate(-5%, -5%);
}

@media only screen and (max-width: 750px) {

    #contact-text {
        font-size: 50px;
        left: -0.25rem;
        top: -0.25rem;
    }


    #phone-img {
        height: 100px;
    }

    #top-right-img, #bottom-left-img {
        transform: scale(0.7);
    }
}


@media only screen and (max-width: 350px) {

    #contact-text {
        font-size: 40px;
        left: 0;
        top: -0.25rem;
    }


    #phone-img {
        height: 80px;
    }

    #top-right-img, #bottom-left-img {
        transform: scale(0.6);
    }
}


@media only screen and (max-width: 220px) {

    #contact-text {
        font-size: 30px;
        left: 0;
        top: -0.25rem;
    }


    #phone-img {
        height: 60px;
    }

    #top-right-img, #bottom-left-img {
        transform: scale(0.5);
    }
}